export const fetchHealthNetworks = function (setHealthNetworkList) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let healthNetworks = [];

  fetch(`${document.location.origin}/realms/master/health-network/`, options)
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return healthNetworks;
    }
  })
  .then((json) => {
    const healthNetworks = json;
    setHealthNetworkList(healthNetworks);
    return healthNetworks;

  })
  .catch((error) => {
    console.log('Error:', error);
  });
}

export class HealthNetwork {

  constructor() {
    this.id = '';
    this.name = '';
    this.displayName = '';
  }

  getId() {
    return this.id
  }
  getName() {
    return this.name
  }
  getDisplayName() {
    return this.displayName
  }

}
