import React, { useState, useEffect } from 'react';

import {Card, CardTitle, CardBody} from '@patternfly/react-core';
import {FormSelect, FormSelectOption} from '@patternfly/react-core';
import {Form, FormGroup, ActionGroup, Button } from '@patternfly/react-core';

import { useCookies } from 'react-cookie';

import { fetchHealthNetworks } from "../../objects/healthNetwork";

export const HealthNetworkForm = () => {

  const [cookies, setCookie] = useCookies(['HealthNetwork']);
  const [healthNetwork, setHealthNetwork] = useState('');

  const formProps = {
    healthNetwork,
    setHealthNetwork,
  }

  function onSubmit(e) {
    e.preventDefault();
    let newHealthNetwork = healthNetwork;
    setCookie('HealthNetwork', newHealthNetwork, { path: '/', domain: document.location.hostname, secure: true, sameSite: 'strict' });
  };

  return <Form className="comm-form">
      <FormGroup label="Select your Health Network" isRequired fieldId="health-network" >
          <HealthNetworkSelect id="health-network" { ...formProps }/>
      </FormGroup>
      <ActionGroup className='pf-u-m-0'>
        <Button className="pf-c-button pf-m-primary pf-u-text-align-center pf-u-w-100" variant="primary" onClick={onSubmit}>Continue</Button>
      </ActionGroup>
    </Form>;
};

export const HealthNetworkSelect = (props) => {
  const { healthNetwork, setHealthNetwork } = props;
  const handleHealthNetworkChange = newHealthNetwork => {
    setHealthNetwork(newHealthNetwork);
  };
  const [ healthNetworksList, setHealthNetworkList ] = useState([]);
  const [ loadHealthNetworks, setLoadHealthNetworks ] = useState(true);
  const [ selectOptions, setSelectOptions ] = useState([{
    value: '',
    label: 'Select one',
    disabled: true,
    isPlaceholder: true,
  }]);

  useEffect(() => {
    if (loadHealthNetworks) {
      fetchHealthNetworks(setHealthNetworkList);
      setLoadHealthNetworks(false);
    }
  }, [ loadHealthNetworks ]);

  let options = [{ ...selectOptions[0] }];
  for (const network of healthNetworksList) {
    options.push({
      value: network.name,
      label: network.displayName,
      disable: false,
      isPlaceholder: false,
    })
  }

  return <FormSelect id={props.id} value={healthNetwork?healthNetwork:''} onChange={handleHealthNetworkChange} aria-label="FormSelect Input">
      {options.map((option, index) => <FormSelectOption isDisabled={option.disabled} key={index} value={option.value} label={option.label} />)}
    </FormSelect>;
};

export const SelectorCard = (props) => (
    <Card id={props.id} className={props.className} style={props.style}>
      <CardTitle className='pf-u-text-align-center'>
        <div className="comm-logo-st pf-u-mb-sm pf-u-mx-auto"/><br/>
        <p className='pf-u-text-align-left'>Login</p>
      </CardTitle>
      <CardBody>
        <HealthNetworkForm/>
      </CardBody>
    </Card>
);
