import React, { useState, useEffect } from 'react';

import { SelectorCard } from './components/healthNetwork/selector';
import { FooterBanner } from './components/footerBanner';
import { Spinner } from '@patternfly/react-core';

import { useCookies } from 'react-cookie';

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [baseDomain] = useState(document.location);
  const [cookies] = useCookies(['HealthNetwork']);

  const setApplicationQuery = () => {
    let params;
    if (baseDomain.search.endsWith("/")) {
      params = new URLSearchParams(baseDomain.search.substring(0, baseDomain.search.length - 1));
    } else {
      params = new URLSearchParams(baseDomain.search);
    }
    if (params.has("redirect_uri")) {
      let redirect_uri = params.get("redirect_uri")
      if (redirect_uri.match(/^https:\/\/.*\/?$/)) {
        params.set("redirect_uri", redirect_uri.replace(/--realm--/g, cookies.HealthNetwork));
      } else {
        params.set("redirect_uri", baseDomain.origin + "/realms/" + cookies.HealthNetwork + "/account/");
      }
    } else {
      params.set("redirect_uri", baseDomain.origin + "/realms/" + cookies.HealthNetwork + "/account/");
    }
    return params;
  };

  useEffect(() => {
    if (cookies.HealthNetwork && cookies.HealthNetwork !== '') {
      let applicationLogin = setApplicationQuery();
      document.location.replace(applicationLogin.get("redirect_uri"));
    } else {
      setIsLoading(false);
    }
  });

  return (
    <div className="pf-l-stack">
      <div className="pf-l-stack__item"></div>
      <div className="pf-l-stack__item pf-m-fill pf-l-bullseye">
        {isLoading ?
          <div>
            <Spinner isSVG diameter="60px"
              style={{ '--pf-c-spinner--Color': '#F98C2D', '--pf-c-spinner--diameter': '60px' }}
              aria-label="Contents of the custom size example" />
          </div> :
          <SelectorCard id="comm-main-card" className="comm-main-card pf-l-bullseye__item pf-u-w-33 pf-u-my-xl pf-u-min-width pf-u-max-width" />
        }
      </div>
      <div className="pf-l-stack__item">
        {!isLoading && <FooterBanner />}
      </div>
    </div>
  );
}

export default App;
