import React from 'react';

export const FooterBanner = () => {
  return (
    <div className="pf-u-background-color-200 pf-u-text-align-center pf-u-font-size-xs">
      <p className="pf-u-p-sm">
        Need help? Please contact your local Communicate administrator<br/>
        <span className="pf-u-color-400">&copy; {new Date().getFullYear()} Orion Health Group of Companies. All rights reserved.</span>
      </p>
    </div>
  );
}
